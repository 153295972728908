<template>

  <v-tooltip location="bottom" :text="theme.name.value === 'dark' ?  'light mode' : 'dark mode'">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        :icon="theme.name.value === 'dark' ? 'mdi-weather-sunny' : 'mdi-weather-night'"
        color='navbar'
        size='small'
        @click='toggleTheme'
      >
      </v-btn>
    </template>
  </v-tooltip>
</template>

<script lang='ts' setup>

import { useTheme } from 'vuetify'

const theme = useTheme()
const toggleTheme = () => {
  const newTheme = theme.global.current.value.dark ? 'light' : 'dark';
  theme.global.name.value = newTheme;
  localStorage.setItem('theme', newTheme);
}
const initializeTheme = () => {
  const savedTheme = localStorage.getItem('theme')
  if (savedTheme) {
    theme.global.name.value = savedTheme
  } else {
    const defaultTheme = 'dark'
    theme.global.name.value = defaultTheme
    localStorage.setItem('theme', defaultTheme)
  }
}

initializeTheme()
</script>
