import { defineStore } from 'pinia'
import AuthService from '@/services/authService/AuthService'
import Cookies from 'js-cookie'

// Define the state interface
interface AuthState {
  registerData: RegisterData | null
  phone_number: string
  email: string
  name: string
  last_name: string
  company_name: string
  step: number
  registration_id: string
  loginUser: {
    email: string
    password: string
  }
  userTokens: {
    access: string
    refresh: string
  }
  externalInvitationStep: number
  externalName: string
  externalLastName: string
  externalPhoneNumber: string
  externalRegistration_id: string
  externalInvitationEmail: string
  externalCompanyName: string
}

// Define the data required for resetting password
interface ResetPasswordData {
  email: string
}

// Define actions
interface AuthActions {
  registerStepOne(data: any): void
  resendCodeStepOne(data: any): void
  login(data: any): any
  userResetPassword(data: ResetPasswordData): void
  registerStepThree(data: any): void
  registerStepTwo(data: any): void
  saveUserDataOnLogin(data: { email: string; password: string }): void
  refreshToken(data: any): any
  logout(): void
  confirmPasswordAction(uid: any, token: any, data: any): any
  registerExternalInvitationStepOne(data: any, id: any): void
  registerExternalInvitationStepTwo(data: any, id: any): void
  resendCodeExternalStepOne(data: any, id: any): void
  registerExternalInvitationStepThree(data: any, id: any): void
}

// Define the structure of register data
export interface RegisterData {
  email: string
  name: string
  phone_number: string
}

// Define the store
export const useAuthStore = defineStore({
  id: 'authStore',
  state: (): AuthState => ({
    registerData: null,
    phone_number: '',
    email: '',
    name: '',
    last_name: '',
    company_name: '',
    step: 0,
    registration_id: '',
    loginUser: {
      email: '',
      password: ''
    },
    userTokens: {
      access: '',
      refresh: ''
    },
    externalInvitationStep: 0,
    externalName: '',
    externalLastName: '',
    externalPhoneNumber: '',
    externalRegistration_id: '',
    externalInvitationEmail: '',
    externalCompanyName: ''
  }),
  actions: {
    registerStepOne(this: AuthState, data: any) {
      const parsedData = JSON.parse(data)
      this.email = parsedData.email
      this.name = parsedData.name
      this.phone_number = parsedData.phone_number
      this.company_name = parsedData.company_name
      this.last_name = parsedData.last_name
      AuthService.registerUser(data).then((res) => {
        this.registration_id = res.registration_id
        this.step = 1
      })
    },
    resendCodeStepOne(this: AuthState, data: any) {
      const parsedData = JSON.parse(data)
      if (parsedData.step === 1) {
        this.email = parsedData.email
        this.name = parsedData.name
        this.last_name = parsedData.last_name
        this.phone_number = parsedData.phone_number
        this.company_name = parsedData.company_name
      } else {
        this.email = parsedData.new_email
      }
      AuthService.registerUser(data).then((res) => {
        this.registration_id = res.registration_id
      })
    },
    registerStepTwo(this: AuthState, data: any) {
      AuthService.registerUser(data).then((res) => {
        this.phone_number = res.phone_number
        this.step = 2
      })
    },
    registerStepThree(this: AuthState, data: any) {
      AuthService.registerUser(data).then(() => {
        this.step = 3
      })
    },
    async login(this: AuthState, data: any) {
        this.userTokens = await AuthService.loginRequest(data)
    },
    async refreshToken(this: AuthState, data: any) {
      try {
        await AuthService.createRefreshToken(data).then((res) => {
          this.userTokens.access = res.access
        })
      } catch (error: any) {
        throw error
      }
    },
    userResetPassword(data: ResetPasswordData) {
      AuthService.resetPassword(data)
    },
    saveUserDataOnLogin(this: AuthState, data: any) {
      this.loginUser.email = data.email
      this.loginUser.password = data.password
    },
    logout(this: AuthState) {
      this.userTokens.access = ''
      this.userTokens.refresh = ''
      this.loginUser.password = ''
      this.loginUser.email = ''
      Cookies.remove('access_token')
      Cookies.remove('refresh_token')
      Cookies.remove('email')
      Cookies.remove('password')
    },
    async confirmPasswordAction(uid: any, token: any, data: any) {
      try {
        await AuthService.confirmPassword(uid, token, data)
      } catch (e: any) {
        throw e
      }
    },
    async registerExternalInvitationStepOne(this: AuthState, data: any, id: any) {
      const parsedData = JSON.parse(data)
      this.externalName = parsedData.name
      this.externalLastName = parsedData.last_name
      this.externalPhoneNumber = parsedData.phone_number
      this.externalCompanyName = parsedData.company_name
      AuthService.registerExternalUser(data, id).then((res) => {
        this.externalRegistration_id = res.registration_id
        this.externalInvitationEmail = res.email
        this.externalInvitationStep = 1
      })
    },
    resendCodeExternalStepOne(this: AuthState, data: any, id: any) {
      const parsedData = JSON.parse(data)
      this.externalName = parsedData.name
      this.externalPhoneNumber = parsedData.phone_number
      this.externalLastName = parsedData.last_name
      this.externalCompanyName = parsedData.company_name
      this.externalInvitationEmail = parsedData.email
      AuthService.registerExternalUser(data, id).then((res) => {
        this.externalRegistration_id = res.registration_id
      })
    },
    registerExternalInvitationStepTwo(this: AuthState, data: any, id: any) {
      AuthService.registerExternalUser(data, id).then((res) => {
        console.log(res)
        this.externalInvitationEmail = res.email
        this.externalInvitationStep = 2
      })
    },
    registerExternalInvitationStepThree(this: AuthState, data: any, id: any) {
      AuthService.registerExternalUserStepThree(data, id).then(() => {
        this.externalInvitationStep = 3
      })
    }
  } as AuthActions
})
