<script lang="ts" setup>
const props = defineProps<{
  isLoading: boolean
}>()
</script>
<template>
  <v-overlay class="align-center justify-center" v-model="props.isLoading">
    <v-progress-circular color="primary" indeterminate :size="59" :width="6"></v-progress-circular>
  </v-overlay>
</template>
<style lang="scss" scoped></style>
