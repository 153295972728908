<script lang="ts">
import { ref, computed, onMounted, nextTick, onUnmounted } from 'vue'
import SvgLoader from './SvgLoader.vue'
import { useTheme } from 'vuetify'
import avatar1 from '../assets/images/announcement/avatars/avatar1.jpg'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/authentication/AuthStore.ts'
import { useUserStore } from '@/store/user/UserStore.ts'
import { useI18n } from 'vue-i18n'
import logo from '../assets/images/lastfloors/lastfloorsLogo.png'
import { useNotificationStore } from '@/store/notification/NotificationStore.ts'
import placeholder from "../../src/assets/images/placeHolders/logo-placeholder.jpg";
import darkThemeImage from "@/assets/img.png"
import lightThemeImage from "@/assets/img_2.png"

interface CustomListItem {
  icon: string
  text: string
  path: string
}

export default {
  computed: {
    placeholder() {
      return placeholder
    }
  },
  components: { SvgLoader },
  setup() {
    const { t, locale } = useI18n({ useScope: 'global' })
    const router = useRouter()
    const authStore = useAuthStore()
    const userStore = useUserStore()
    const userData = computed(() => userStore.$state)
    const isLogoutModalOpen = ref(false)
    const isMobile = ref(window.innerWidth < 767)
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 767
      if (isMobile.value !== newIsMobile) {
        isMobile.value = newIsMobile
        nextTick(() => {
          // Additional UI adjustments can be made here if necessary
        })
      }
    }

    onMounted(() => {
      userStore.fetchUser()
      window.addEventListener('resize', handleResize)
    })

    onMounted(async () => {
      await notificationStore.fetchUnreadCounter()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    const goToProfile = () => {
      router.push('/user-profile')
    }

    const notificationStore = useNotificationStore()
    const totalNotifications = computed(
      () =>
        notificationStore.unreadCounter +
        notificationStore.requestCounter +
        notificationStore.invitationCounter
    )

    const goToNotifications = () => {
      router.push('/notifications')
    }

    const logout = () => {
      isLogoutModalOpen.value = false
      authStore.logout()
      nextTick(() => {
        router.push('/login')
      })
    }

    const items = [
      { text: 'sideNavBar.buttonOverview', name: 'mdi-view-dashboard-outline', path: '/', child: false },
      { text: 'sideNavBar.buttonRsMangement', name: 'mdi-city-variant-outline', path: '/my-real-estates', child: false },
      {
        text: 'sideNavBar.networking',
        name: 'mdi-account-group-outline',
        path: '/networking',
        child: false
      },
      {
        text: 'sideNavBar.buttonMarketplace',
        name: 'mdi-cards-outline',
        path: '/marketplace',
        child: false
      },
      {
        text: 'sideNavBar.buttonAnnouncementWall',
        name: 'mdi-bullhorn-outline',
        path: '/announcement',
        child: false
      }
    ]

    const customDrawerList: CustomListItem[] = [
      { icon: 'mdi-view-dashboard-outline', text: 'sideNavBar.buttonOverview', path: '/' },
      { icon: 'mdi-city-variant-outline', text: 'sideNavBar.allPortfolio', path: '/my-real-estates' },
      {
        icon: 'mdi-account-group-outline',
        text: 'sideNavBar.networking',
        path: '/networking'
      },
      { icon: 'mdi-cards-outline', text: 'sideNavBar.buttonMarketplace', path: '/marketplace' },
      {
        icon: 'mdi-bullhorn-outline',
        text: 'sideNavBar.buttonAnnouncementWall',
        path: '/announcement'
      },
      { icon: 'mdi-logout', text: 'sideNavBar.logOut', path: '/login' }
    ]

    const customList: CustomListItem[] = [
      { icon: 'mdi-crown-outline', text: 'topNavBar.buttonSubplan', path: '/subscription-plan' },
      { icon: 'mdi-account-outline', text: 'topNavBar.buttonProfile', path: '/user-profile' },
      {
        icon: 'mdi-briefcase-outline',
        text: 'topNavBar.buttonCompanyProfile',
        path: '/company-profile'
      }
    ]

    const theme = useTheme()

    const drawer = ref(true)
    const rail = ref(false)

    const model = computed({
      get: () => drawer.value,
      set: (value) => {
        drawer.value = value
      }
    })

    const topNav = ref({
      avatar: avatar1
    })

    const toggleDropdown = (item: any) => {
      item.dropdown = !item.dropdown
    }

    const toggleTheme = () => {
      const newTheme = theme.global.current.value.dark ? 'light' : 'dark';
      theme.global.name.value = newTheme;
      localStorage.setItem('theme', newTheme);
    }
    const initializeTheme = () => {
      const savedTheme = localStorage.getItem('theme')
      if (savedTheme) {
        theme.global.name.value = savedTheme
      } else {
        const defaultTheme = 'dark'
        theme.global.name.value = defaultTheme
        localStorage.setItem('theme', defaultTheme)
      }
    }

    initializeTheme()

    const toggleLogoutModal = () => {
      isLogoutModalOpen.value = !isLogoutModalOpen.value
    }

    const themeImageUrl = computed(() => {
      return theme.global.current.value.dark === true ? darkThemeImage : lightThemeImage
    })
    return {
      items,
      model,
      goToProfile,
      toggleTheme,
      theme,
      topNav,
      toggleDropdown,
      logout,
      userData,
      t,
      locale,
      customList,
      customDrawerList,
      rail,
      drawer,
      isLogoutModalOpen,
      toggleLogoutModal,
      logo,
      isMobile,
      handleResize,
      goToNotifications,
      totalNotifications,
      themeImageUrl,
      darkThemeImage,
      lightThemeImage,
      initializeTheme
    }
  }
}
</script>

<template>
  <div>
    <v-navigation-drawer color='drawer' clipped v-if="!isMobile" v-model="model" :class="{ 'rail-mode': rail }" :rail="rail" :width="250"
                         absolute app class="primary drawer" left permanent @click="rail = false">
      <v-list active-class="selected_item" class="mt-8" density="compact">
        <v-list-subheader class="px-0 flex justify-center items-center" style="padding-inline-start: 0 !important">
          <div class='px-2'>
            <div v-if="rail">
              <SvgLoader class="mt-1.5" name="smallLogo" style="stroke: none" />
            </div>
            <div v-else class="flex justify-between items-center mt-3">
              <div>
<!--                <SvgLoader :name="'drawerLogo'" style="stroke: none" />-->
                <img :src="themeImageUrl" alt='lastfloors'>
              </div>
              <v-icon style="margin-left: 70px" color="primary" icon="mdi-radiobox-marked"
                      @click.stop="rail = !rail"></v-icon>
            </div>
          </div>
        </v-list-subheader>

        <div class="custom-margin">
          <template v-for="(item, i) in items">
            <!-- Render items without children -->
            <v-list-item
              variant="flat"
              base-color='background'
              color='primary'
              rounded='lg'
              :class="[!item.child ? 'default-opacity' : 'custom-opacity']"
              active-class='parent-items'
              :title='$t(item.text)'
              v-if="!item.child"
              :key="'item-' + i"
              :to="item.path"
              class="mt-3 mx-2"
            >
              <template #prepend>
                <v-icon :icon='item.name' size='large'></v-icon>
              </template>
            </v-list-item>

            <!-- Render items with children -->
            <v-list-group v-else :key="'group-' + i" :value="item.text">
              <template v-slot:activator="{ props, isOpen }">
                <v-list-item
                  variant="flat"
                  base-color='background'
                  color='primary'
                  rounded='lg'
                  :class="['default-opacity']"
                  active-class='parent-items'
                  :append-icon="rail ? '' : ''"
                  :to="item.path"
                  class="mt-3 mx-2"
                  v-bind="props"
                >
                  <template #prepend v-if='rail'>
                    <v-icon :icon='item.name'></v-icon>
                  </template>
                  <div class="flex justify-between gap-x-2">
                    <div class="flex gap-x-1 items-center">
                      <v-list-item-title variant="flat" base-color='background' color='primary' rounded='xl' active-color='primary' v-if="!rail">{{ $t(item.text) }}</v-list-item-title>
                    </div>
                    <v-icon v-if="!rail">
                      {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </div>
                </v-list-item>
              </template>

              <!-- Render child items -->
              <div v-if="!rail" class="mt-3">
                <v-list-item
                  rounded='lg'
                  color='primary'
                  :prepend-icon='child.name'
                  :class="['custom-opacity']"
                  active-class='child-items'
                  v-for="(child, j) in item.children"
                  :key="'child-' + j"
                  :to="child.path"
                  class='mx-4'
                >
                  <template #title>
                    <span v-if='!rail'>{{ $t(child.text) }}</span>
                  </template>
                </v-list-item>
              </div>
            </v-list-group>
          </template>


        </div>
      </v-list>
      <template v-slot:append>
        <div class="pb-4 flex items-start justify-start">
          <v-btn prepend-icon='mdi-logout' variant="text" v-if="!rail"
                 @click="toggleLogoutModal" color='primary' class='ml-2'>
            <span class='capitalize'>{{ $t('sideNavBar.logOut') }}</span>
          </v-btn>
          <v-icon color='primary' class="ml-5" v-if="rail" @click="toggleLogoutModal" block icon='mdi-logout'></v-icon>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar clipped-left app rounded class="md:mx-11 my-7" color="navbar" height="70"   :class="{ 'expanded-title': rail || isMobile, 'rail-active-toolbar': rail }">

      <v-menu v-if="isMobile" offset-y>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi mdi-menu" v-bind="props"></v-btn>
        </template>
        <v-card color='background'>
            <v-list-item
              v-for="(item, index) in customDrawerList"
              :key="index"
              :to="item.path"
              color='primary'
              class='custom-opacity'
              v-show="index < 3 || isMobile"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon left>{{ item.icon }}</v-icon>
                  {{ $t(item.text) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </v-card>
      </v-menu>

      <v-app-bar-title>
        <router-link :to="{ name: 'CompanyProfile' }">
          <div class="flex items-center">
            <img v-if="!isMobile" :src="userData.company.logo || placeholder " alt="company logo" class="rounded-full object-fill bg-theme-5 h-14 w-14" />
            <span v-if="!isMobile" class="ml-4 capitalize">{{ userData.company.company_name }}</span>
          </div>
        </router-link>
      </v-app-bar-title>


        <v-btn @click="toggleTheme" size='large' :icon="theme.name.value === 'dark' ? 'mdi-weather-sunny' : 'mdi-weather-night'">
        </v-btn>
        <!-- <v-btn icon="mdi-dots-vertical"></v-btn> -->
      <v-hover v-slot="{ isHovering, props }" open-delay="100">
        <v-btn icon size="large" @click="goToNotifications">
          <v-badge :class="{ 'on-hover': isHovering }" v-bind="props" :content="totalNotifications"
                   :model-value="totalNotifications > 0" color="primary" :dot="!isHovering">
            <v-icon :class="{ 'shake-animation': totalNotifications > 0 }">mdi mdi-bell-outline</v-icon>
          </v-badge>
        </v-btn>
      </v-hover>
      <v-divider color='primary' vertical class='mr-2 my-4'></v-divider>

      <v-menu min-width="300px" rounded>
        <v-card> </v-card>
      </v-menu>
      <div class="flex justify-between cursor-pointer">
        <div @click="goToProfile" class='flex justify-center items-center'>
          <v-avatar size="small">
            <v-img :src="userData.avatar" alt="Avatar"></v-img>
          </v-avatar>
        </div>
        <div class="ml-2 flex flex-col" @click="goToProfile">
          <span v-if="!isMobile" class="name-label">{{ userData.name || 'Name' }}</span>
          <span v-if="!isMobile" class="name-label text-xs">{{ userData.position }}</span>
        </div>
      </div>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn class="mr-2" icon="mdi mdi-chevron-down" v-bind="props"></v-btn>
        </template>

        <v-card color='background'>
          <div>
            <div v-if="isMobile" class="flex justify-between mt-4 w-2/2">
              <div class="text-center gap-y-1 flex flex-col justify-center w-1/2">
                <router-link :to="{ name: 'Profile' }">
                  <div>
                    <v-avatar size='30'>
                      <v-img contain class='bg-theme-5' :src="userData.avatar"></v-img>
                    </v-avatar>
                    <p class="text-sm">{{ userData.name }}</p>
                    <p class="text-sm">{{ userData.last_name }}</p>
                    <p class="text-xs">{{ userData.position }}</p>
                  </div>
                </router-link>
              </div>

              <div class="text-center w-1/2">
                <router-link :to="{ name: 'CompanyProfile' }">
                  <div class='flex justify-center gap-y-1 flex-col items-center'>
                    <v-avatar size='30'>
                      <v-img :src="userData.company.logo || placeholder"></v-img>
                    </v-avatar>
                    <span class="text-sm">{{ userData.company.company_name }}</span>
                  </div>
                </router-link>
              </div>

            </div>
            <v-divider v-if='isMobile' color='primary' class='my-2'></v-divider>
            <div>
              <v-list-item v-for="(item, index) in customList" :key="index" :to="item.path"
                           v-show="index < 3 || isMobile">
                <div class="flex gap-1 items-center">
                  <!--                <SvgLoader :name="item.icon" />-->
                  <v-icon :icon='item.icon'></v-icon>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </div>
              </v-list-item>
            </div>
          </div>
        </v-card>
      </v-menu>
    </v-app-bar>
    <template>
      <v-dialog v-model="isLogoutModalOpen" max-width="400">
        <v-card color='background'>
          <v-card-title>{{ $t('sideNavBar.logoutModalTitle') }}</v-card-title>
          <v-card-text>{{ $t('sideNavBar.logoutModalText') }}</v-card-text>
          <v-card-actions>
            <v-btn color="primary" variant='outlined' @click="logout">{{ $t('sideNavBar.logoutModalYes') }}</v-btn>
            <v-btn @click="toggleLogoutModal">{{ $t('sideNavBar.logoutModalNo') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<style lang="scss" scoped>
::v-deep(.v-list-item__prepend) {
  width: 35px !important;
}
::v-deep(.v-list-item--active > .v-list-item__overlay, .v-list-item[aria-haspopup=menu][aria-expanded=true] > .v-list-item__overlay) {
  opacity: 0 !important;
}

::v-deep(.custom-opacity.v-list-item--active > .v-list-item__overlay) {
  opacity: calc(var(--v-activated-opacity) * var(--v-theme-overlay-multiplier)) !important;
}

::v-deep(.default-opacity .v-list-item__overlay) {
  opacity: var(--v-overlay-opacity, 0) !important;
}
::v-deep(.v-list-item-title) {
    color: var(--v-theme-primary) !important;
    font-size: 14px;
}

.custom-margin {
  margin-top: 75px;
}

.rail-mode {
  width: 70px !important;
  min-width: 60px !important;
}


.rail-mode .v-list-subheader .flex {
  align-items: center;
  justify-content: space-between;
}

.v-list-subheader svg {
  width: 100%;
  height: auto;
  max-height: 100%;
}


.v-navigation-drawer--active {
  position: fixed !important;
}

.divider {
  height: 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
@media (prefers-color-scheme: light) {
  .drawer {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.1) !important;
  }
  .v-toolbar {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.1) !important;
  }
}

.name-label {
  color: var(--v-theme-primary-base);
}

.text-xs {
  font-size: 0.75rem;
}
@media only screen and (max-width: 768px) {
  .v-toolbar {
    display: flex !important;
    width: calc(100% - 65px) !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 2rem !important;
  }
}
@media only screen and (min-width: 768px) {
  .v-toolbar {
    width: calc(100% - 330px) !important;
  }
  .rail-active-toolbar {
    width: calc(100% - 130px) !important;
  }
}
@keyframes shake {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
}

.shake-animation {
  animation: shake 0.5s ease-in-out infinite;
}
.dark-menu {
  background-color: var(--v-theme-background);
  color: var(--v-theme-on-background);
}

.light-menu {
  background-color: var(--v-theme-background);
  color: var(--v-theme-on-background);
}

.v-list-item-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.v-list-item {
  color: var(--v-theme-primary);
}
</style>
