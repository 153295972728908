<template>
  <v-breadcrumbs v-if="breadcrumbs.length > 0" divider="/">
    <template v-slot:prepend v-if="hasBackButton">
      <v-icon icon="mdi-chevron-left" size="x-large"></v-icon>
    </template>
    <template v-for="(breadcrumb, index) in breadcrumbs" :key="index">
      <v-breadcrumbs-item
        v-if="breadcrumb.to"
        :to="breadcrumb.to"
        :disabled="index === breadcrumbs.length - 1"
      >
        {{ breadcrumb.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script lang="ts">
import { computed } from 'vue'
import { useRoute, RouteRecordNormalized } from 'vue-router'

interface Breadcrumb {
  to?: string
  text: string
  hasBackButton: boolean
}

export default {
  setup() {
    const route = useRoute()
    const hasBackButton = computed(() => {
      let isExist = false
      ;(route.meta.breadcrumbs as Breadcrumb[]).forEach((el) => {
        if (el.hasBackButton) {
          isExist = true
        }
      })
      return isExist
    })
    const breadcrumbs = computed(() => {
      const matchedRoutes = route.matched as RouteRecordNormalized[]

      const crumbs: Breadcrumb[] = []

      matchedRoutes.forEach((route) => {
        if (route.meta && route.meta.breadcrumbs) {
          // Dereference the Ref object and push elements into it
          crumbs.push(...(route.meta.breadcrumbs as Breadcrumb[]))
        }
      })

      // Return the array, not the Ref object
      return crumbs
    })

    return {
      breadcrumbs,
      hasBackButton
    }
  }
}
</script>
