import { defineStore } from 'pinia'

interface AppState {
  locale: string;
}

export const useAppStore = defineStore({
  id: 'app',
  state: (): AppState => ({
    locale: localStorage.getItem('lang') || 'en' // Initialize with saved language or default to 'en'
  }),
  actions: {
    setLocale(newLocale: string) {
      this.locale = newLocale
      localStorage.setItem('lang', newLocale) // Save language preference to local storage
    }
  },
  getters: {
    currentLocale: (state) => state.locale
  }
})
