const routes: Array<any> = [
  {
    path: '/',
    name: 'Overview',
    component: () => import('@/pages/Overview.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/real-estate-management',
    name: 'RealEstateManagement',
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    },
    children: [
      {
        path: '/my-portfolio',
        name: 'MyPortfolio',
        component: () => import('@/pages/RealEstateManagement/MyPortfolio.vue'),
        meta: {
          layout: 'Dashboard',
          requiresAuth: true
        }
      },
      {
        path: '/create-new-project',
        name: 'CreateNewProject',
        component: () => import('@/pages/RealEstateManagement/CreateNewProject.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/project/:id/edit',
        name: 'EditRealestate',
        component: () => import('@/pages/RealEstateManagement/CreateNewProject.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/my-real-estates',
        name: 'View',
        component: () => import('@/pages/RealEstateManagement/MyRealEstates.vue'),
        meta: {
          layout: 'Dashboard',
          requiresAuth: true
        }
      },
      {
        path: '/other-real-estates',
        name: 'otherView',
        component: () => import('@/pages/RealEstateManagement/MyRealEstates.vue'),
        meta: {
          layout: 'Dashboard',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/announcement',
    name: 'AnnouncementWall',
    component: () => import('@/pages/AnnouncementWall.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/announcement-details/:type/:id',
    name: 'announcementDetails',
    component: () => import('@/pages/AnnouncementWallDetails.vue'),
    props: true,
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
      /*    breadcrumbs: [
        { text: 'Home', to: '/announcement' },
        { text: 'Announcement Details', to: '/announcement-details/:id/' }
      ]*/
    }
  },
  {
    path: '/create-announcement',
    name: 'CreateAnnouncement',
    component: () => import('@/pages/CreateAnnouncement.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/edit-announcement/:id',
    name: 'EditAnnouncement',
    component: () => import('@/pages/CreateAnnouncement.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
    meta: {
      layout: 'Default'
    }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import('@/pages/ForgetPassword.vue'),
    meta: {
      layout: 'Default'
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('@/pages/SignUp.vue'),
    meta: {
      layout: 'Default'
    }
  },
  {
    path: '/sign-up/invitation',
    name: 'InvitationSignup',
    component: () => import('@/pages/ExternalInvitation.vue'),
    meta: {
      layout: 'Default'
    }
  },
  {
    path: '/user-profile',
    name: 'Profile',
    component: () => import('@/pages/Profile.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/company-profile',
    name: 'CompanyProfile',
    component: () => import('@/pages/CompanyProfile.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/company-profile-staff',
    name: "CompanyProfileStaff",
    component: () => import("@/components/companyProfile/CompanyProfileStaff.vue"),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  // {
  //   path: '/invite-staff',
  //   name: "inviteStaff",
  //   component: () => import("@/components/companyProfile/InviteStaff.vue"),
  //   meta: {
  //     layout: 'Dashboard',
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/sign-up/staff-invitation',
    name: "staff-invitations",
    component: () => import("@/components/companyProfile/StaffSignUp.vue"),
    meta: {
      layout: 'Default'
    }
  },
  // {
  //   path: '/project-management',
  //   name: 'ProjectManagement',
  //   component: () => import('@/pages/ProjectManagement.vue'),
  //   meta: {
  //     layout: 'Dashboard',
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/real-estate',
  //   name: 'RealEstate',
  //   meta: {
  //     layout: 'Dashboard',
  //     requiresAuth: true
  //   },
  //   children: [
  //     {
  //       path: '/connected',
  //       name: 'ConnectedAgencies',
  //       component: () => import('@/pages/ConnectedAgencies.vue'),
  //       meta: {
  //         layout: 'Dashboard',
  //         requiresAuth: true
  //       }
  //     },
  //     {
  //       path: '/invite-new-agent',
  //       name: 'InviteNewAgency',
  //       component: () => import('@/pages/InviteNewAgency.vue'),
  //       meta: {
  //         layout: 'Dashboard',
  //         requiresAuth: true
  //       }
  //     },
  //     {
  //       path: '/invitation-form',
  //       name: 'InvitationForm',
  //       component: () => import('@/components/realEstateAgency/InvitationForm.vue'),
  //       meta: {
  //         layout: 'Dashboard',
  //         requiresAuth: true
  //       }
  //     },
  //     {
  //       path: 'real-estate-details/:companyName/:mainPhoto',
  //       name: 'RealEstateDetails',
  //       props: true,
  //       component: () => import('@/pages/RealEstateDetails.vue'),
  //       meta: {
  //         layout: 'Dashboard',
  //         requiresAuth: true
  //       }
  //     }
  //   ]
  // },
  {
    path: '/request',
    name: 'Request',
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    },
    children: [
      {
        path: 'sent-request',
        name: 'SentRequest',
        component: () => import('@/pages/SentRequest.vue'),
        meta: {
          layout: 'Dashboard',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/offers',
    name: 'offers',
    component: () => import('@/pages/networking/Offers.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/property-details/:propertyId',
    name: 'PropertyDetails',
    component: () => import('@/pages/RealEstateManagement/PropertyDetails.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/real-estate-details/:realEstateId',
    name: 'RealEstateDetails',
    component: () => import('@/pages/RealEstateManagement/RealEstateDetails.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    },
    breadcrumbs: [
      { text: 'Back to my portfolio', to: '/my-portfolio' },
      { text: '', to: '' }
    ]
  },
  {
    path: '/pdf-template/:id?',
    name: 'PDFTemplate',
    component: () => import('@/components/realEstateAgency/PDFTemplate.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  // {
  //   path: '/map-view',
  //   name: 'MapView',
  //   component: () => import('../pages/MapView.vue'),
  //   meta: {
  //     layout: 'Dashboard',
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/create-new-property',
  //   name: 'CreateNewProperty',
  //   component: () => import('../pages/RealEstateManagement/CreateNewProperty.vue'),
  //   meta: {
  //     layout: 'Dashboard',
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/password-reset-confirm/:uidb64/:token',
    name: 'passwordResetConfirm',
    component: () => import('@/pages/PasswordResetConfirm.vue')
  },
  {
    path: '/:notFound',
    name: 'Error404',
    component: () => import('@/pages/Error404.vue')
  },
  {
    path: '/subscription-plan',
    name: 'SubscriptionPlan',
    component: () => import('@/pages/SubscriptionPlan.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/networking',
    name: 'networking',
    component: () => import('@/pages/networking/Networking.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  // {
  //   path: '/networking/invitation',
  //   name: 'networkingExternalInvitation',
  //   component: () => import('@/pages/networking/ExternalInvitationForm.vue'),
  //   meta: {
  //     layout: 'Dashboard',
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/company-detail/:id',
    name: 'NetworkingCompanyDetail',
    component: () => import('@/pages/networking/NetworkingCompanyDetail.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true,
    }
  },
  {
    path: '/marketplace',
    name: 'marketplace',
    component: () => import('@/pages/marketplace/Marketplace.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/marketplace-detail/:id/:type',
    name: 'MarketplaceDetail',
    component: () => import('@/pages/marketplace/MarketplaceDetail.vue'),
    props: true,
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/marketplace/create/:type',
    name: 'MarketplaceCreate',
    component: () => import('@/pages/marketplace/MarketplaceCreate.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/marketplace/create/:type/:id',
    name: 'EditMarketplace',
    component: () => import('@/pages/marketplace/MarketplaceCreate.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/pages/Notifications.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/discover',
    name: 'DiscoverMap',
    component: () => import('@/pages/DiscoverMap.vue'),
    meta: {
      layout: 'Dashboard',
      requiresAuth: true
    }
  }
]

export default routes
