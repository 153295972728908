import BaseService from '../BaseService'

const UserService = {
  // Method for getting user info
  async getUserInfo(): Promise<any> {
    return await BaseService.get<any>('/api/users/info/')
  },
  // Method for updating user info
  async updateUserInfo(data: any): Promise<any> {
    return await BaseService.patch<any>('/api/users/update/', data, 'multipart/form-data')
  }
}

export default UserService
