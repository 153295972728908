import { defineStore } from 'pinia'
import {
  Notification,
  Interaction,
  Networking,
  Invitation
} from '@/interfaces/NotificationInterface.ts'
import notificationServices from '@/services/notificationService/notificationService.ts'
import { useCompanyStore } from '@/store/company/CompanyStore.ts'

interface NotificationState {
  systemNotifications: Notification[]
  nonsystemNotifications: Notification[]
  connectionRequests: Networking[]
  invitations: Invitation[]
  pendingRequests: Networking[]
  nonPendingRequests: Networking[]
  acceptedRequests: Networking[]
  declinedRequests: Networking[]
  interactionNotifications: Interaction[]
  totalNotifications: number
  unreadCounter: number
  requestCounter: number
  invitationCounter: number
  systemNotificationCounter: number
  sender_company: number
  receiver_company: number
  isLoading: boolean
  error: string | null
  currentPage: number
  nextPage: string | null
  previousPage: string | null
  isSender: boolean
  isReceiver: boolean
  networkingCounter: number
}

export const useNotificationStore = defineStore('notificationStore', {
  state: (): NotificationState => ({
    systemNotifications: [],
    nonsystemNotifications: [],
    connectionRequests: [],
    invitations: [],
    pendingRequests: [],
    nonPendingRequests: [],
    acceptedRequests: [],
    declinedRequests: [],
    interactionNotifications: [],
    totalNotifications: 0,
    unreadCounter: 0,
    requestCounter: 0,
    invitationCounter: 0,
    systemNotificationCounter: 0,
    sender_company: 0,
    receiver_company: 0,
    isLoading: false,
    error: null,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    isSender: false,
    isReceiver: false,
    networkingCounter: 0
  }),

  actions: {
    async fetchAllSystemNotifications(page: number = 1) {
      this.isLoading = true
      this.error = null
      try {
        const response = await notificationServices.getSystemNotifications(page)
        const notifications = response.results
        this.systemNotifications = notifications
        this.nextPage = response.next
        this.previousPage = response.previous
        this.currentPage = page
      } catch (error) {
        this.error = 'Error fetching notifications'
        console.error('Error fetching notifications:', error)
      } finally {
        this.isLoading = false
      }
    },
    async fetchPendingConnectionRequests() {
      this.isLoading = true
      this.error = null
      try {
        if (this.receiver_company === 0) {
          await this.setReceiverCompanyId()
        }
        const companyId = this.receiver_company
        const response = await notificationServices.getPendingConnectionRequests()
        this.pendingRequests = response.results
        this.pendingRequests.forEach(
          (request: { sender_company: any; receiver_company: any }) => {
            if (companyId === request.sender_company) {
              this.isSender = true
            }
            if (companyId === request.receiver_company) {
              this.isReceiver = true
            }
          }
        )
      } catch (error) {
        this.error = 'Error fetching pending connection requests'
        console.error('Error fetching pending connection requests:', error)
      } finally {
        this.isLoading = false
      }
    },

    async fetchAcceptedConnectionRequests() {
      this.isLoading = true
      this.error = null
      try {
        const response = await notificationServices.getAcceptedConnectionRequests()
        this.acceptedRequests = response.results
      } catch (error) {
        this.error = 'Error fetching accepted connection requests'
        console.error('Error fetching accepted connection requests:', error)
      } finally {
        this.isLoading = false
      }
    },

    async fetchDeniedConnectionRequests() {
      this.isLoading = true
      this.error = null
      try {
        const response = await notificationServices.getDeniedConnectionRequests()
        this.declinedRequests = response.results
      } catch (error) {
        this.error = 'Error fetching denied connection requests'
        console.error('Error fetching denied connection requests:', error)
      } finally {
        this.isLoading = false
      }
    },
    async fetchNextPage() {
      if (this.nextPage) {
        const nextPageNumber = this.currentPage + 1
        await this.fetchAllSystemNotifications(nextPageNumber)
      }
    },
    async fetchPreviousPage() {
      if (this.previousPage && this.currentPage > 1) {
        const previousPageNumber = this.currentPage - 1
        await this.fetchAllSystemNotifications(previousPageNumber)
      }
    },
    async fetchNextConnectionPage() {
      if (this.nextPage) {
        const nextPageNumber = this.currentPage + 1
        await this.fetchConnectionRequests(nextPageNumber)
      }
    },
    async fetchPreviousConnectionPage() {
      if (this.previousPage && this.currentPage > 1) {
        const previousPageNumber = this.currentPage - 1
        await this.fetchConnectionRequests(previousPageNumber)
      }
    },
    async fetchNextInvitationPage() {
      if (this.nextPage) {
        const nextPageNumber = this.currentPage + 1
        await this.fetchInvitations(nextPageNumber)
      }
    },
    async fetchPreviousInvitationPage() {
      if (this.previousPage && this.currentPage > 1) {
        const previousPageNumber = this.currentPage - 1
        await this.fetchInvitations(previousPageNumber)
      }
    },
    async fetchAllNonsystemNotifications() {
      this.isLoading = true
      this.error = null
      try {
        const response = await notificationServices.getAllNotifications()
        const notifications = response.results
        this.nonsystemNotifications = notifications.filter(
          (notification: { is_system: false }) => !notification.is_system
        )
      } catch (error) {
        this.error = 'Error fetching notifications'
        console.error('Error fetching notifications:', error)
      } finally {
        this.isLoading = false
      }
    },
    async fetchNotificationById(id: number | string) {
      this.isLoading = true
      this.error = null
      try {
        const notification = await notificationServices.getNotificationById(id)
        if (notification.is_system) {
          this.systemNotifications.push(notification)
        } else {
          this.nonsystemNotifications.push(notification)
        }
        this.updateTotalNotifications()
        return notification
      } catch (error) {
        this.error = `Error fetching notification with id ${id}`
        console.error(`Error fetching notification with id ${id}:`, error)
        throw error
      } finally {
        this.isLoading = false
      }
    },
    async markNotificationAsRead(id: number | string) {
      this.isLoading = true
      this.error = null
      try {
        await notificationServices.markAsRead(id)
        this.unreadCounter -= 1
      } catch (error) {
        this.error = `Error marking notification id ${id} as read`
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    updateTotalNotifications() {
      const unreadSystemNotifications = this.systemNotifications.filter(
        (notification: { is_read: any }) => !notification.is_read
      ).length

      this.totalNotifications = unreadSystemNotifications
    },
    async fetchUnreadCounter() {
      this.isLoading = true
      this.error = null
      try {
        const response = await notificationServices.getUnreadCounter()
        const pendingConnectionRequest = await notificationServices.getPendingConnectionRequests()
        const pendingInvitations = await notificationServices.getPendingInvitations()
        this.unreadCounter = response.count
        this.requestCounter = pendingConnectionRequest.count
        this.invitationCounter = pendingInvitations.count
      } catch (error) {
        this.error = 'Error fetching unread notifications count'
        console.error('Error fetching unread notifications count:', error)
      } finally {
        this.isLoading = false
      }
    },
    async setReceiverCompanyId() {
      const companyStore = useCompanyStore()
      this.receiver_company = companyStore.id
    },

    async fetchConnectionRequests(page: number = 1) {
      this.isLoading = true
      this.error = null
      this.isSender = false
      this.isReceiver = false

      try {
        if (this.receiver_company === 0) {
          await this.setReceiverCompanyId()
        }

        const companyId = this.receiver_company

        // Fetch all connection requests
        const response = await notificationServices.getAllConnectionRequests(page)
        this.connectionRequests = response.results
        this.nextPage = response.next
        this.previousPage = response.previous
        this.currentPage = page

        // Check if the companyId matches sender_company or receiver_company
        this.connectionRequests.forEach(
          (request: { sender_company: any; receiver_company: any }) => {
            if (companyId === request.sender_company) {
              this.isSender = true
            }
            if (companyId === request.receiver_company) {
              this.isReceiver = true
            }
          }
        )
      } catch (error) {
        this.error = 'Error fetching connection requests'
        console.error('Error fetching connection requests:', error)
      } finally {
        this.isLoading = false
      }
    },
    async fetchInvitations(page: number = 1) {
      this.isLoading = true
      this.error = null
      try {
        const response = await notificationServices.getAllInvitations(page)
        this.invitations = response.results
        this.nextPage = response.next
        this.previousPage = response.previous
        this.currentPage = page
      } catch (error) {
        this.error = 'Error fetching connection requests'
        console.error('Error fetching connection requests:', error)
      } finally {
        this.isLoading = false
      }
    },
    async acceptConnectionRequest(id: number | string) {
      this.isLoading = true
      this.error = null
      try {
        await notificationServices.acceptRequest(id)
        await this.fetchUnreadCounter() // Refresh counters after accepting request
      } catch (error) {
        this.error = `Error accepting connection request id ${id}`
        console.error(`Error accepting connection request id ${id}:`, error)
      } finally {
        this.isLoading = false
      }
    },

    async rejectConnectionRequest(id: number | string) {
      this.isLoading = true
      this.error = null
      try {
        await notificationServices.rejectRequest(id)
        await this.fetchUnreadCounter() // Refresh counters after rejecting request
      } catch (error) {
        this.error = `Error rejecting connection request id ${id}`
        console.error(`Error rejecting connection request id ${id}:`, error)
      } finally {
        this.isLoading = false
      }
    },

    async revertConnectionRequestToPending(id: number | string) {
      this.isLoading = true
      this.error = null
      try {
        await notificationServices.revertRequestPending(id)
        await this.fetchUnreadCounter() // Refresh counters after reverting request
      } catch (error) {
        this.error = `Error reverting connection request id ${id}`
        console.error(`Error reverting connection request id ${id}:`, error)
      } finally {
        this.isLoading = false
      }
    },

    async deleteRequest(id: number | string) {
      this.isLoading = true
      this.error = null
      try {
        await notificationServices.deleteRequest(id)
        await this.fetchUnreadCounter() // Refresh counters after deleting request
      } catch (error) {
        this.error = `Error deleting connection request id ${id}`
        console.error(`Error deleting connection request id ${id}:`, error)
      } finally {
        this.isLoading = false
      }
    },
    async fetchNetworkingNotifications() {

      try {
        const response = await notificationServices.getNetworkingNotifications();
        this.networkingCounter = response.received_count
      } catch (error) {
        console.error('Error fetching notification count:', error)
      }
    },
  }
})
