<script lang="ts">
import { defineComponent } from 'vue'
import Drawer from '../globalComponents/Drawer.vue'
import Breadcrumbs from '../globalComponents/Breadcrumbs.vue'

export default defineComponent({
  name: 'TheDashboardLayout',
  components: { Drawer, Breadcrumbs }
})
</script>

<template>
  <v-layout>
    <drawer />
    <v-main class="dashboard-main">
      <Breadcrumbs />
      <slot></slot>
    </v-main>
  </v-layout>
</template>

<style scoped>
.dashboard-main {
  margin-top: calc(70px + 1rem);
  margin-left: calc(36px + 10px);
  margin-right: calc(36px + 10px);
  width: -webkit-fill-available !important;
  width: fit-content;
}
</style>
