import BaseService from '../BaseService'

interface ResetPasswordData {
  email: string
}

const AuthService = {
  // Method for registering a user
  async registerUser(userData: any): Promise<any> {
    return await BaseService.post<any>('/api/users/register/', userData)
  },
  async loginRequest(loginData: any): Promise<any> {
    return await BaseService.post<any>('/api/users/login/', loginData)
  },
  async createRefreshToken(token: any): Promise<any> {
    return await BaseService.post<any>('/api/users/token/refresh/', token)
  },
  async resetPassword(resetPasswordData: ResetPasswordData): Promise<any> {
    return await BaseService.post<any>('/api/users/password-reset/', resetPasswordData)
  },
  async confirmPassword(uid: any, token: any, data: any): Promise<any> {
    return await BaseService.post<any>(`/api/users/password-reset-confirm/${uid}/${token}/`, data)
  },
  async registerExternalUser(userData: any, id: any): Promise<any> {
    return await BaseService.post<any>(`/api/users/company/register/?invitation_id=${id}`, userData)
  },
  async registerExternalUserStepThree(userData: any, id: any): Promise<any> {
    return await BaseService.post<any>(
      `/api/users/company/register/?invitation_id=${id}`,
      userData,
      "'multipart/form-data'"
    )
  }
}

export default AuthService
