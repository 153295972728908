<script setup lang="ts">
import { computed } from 'vue'
import { useTheme } from 'vuetify'

// Get the theme
const theme = useTheme()

// Computed property to determine the background class based on the theme
const appBackgroundClass = computed(() => {
  return theme.global.current.value.dark ? 'custom-background-dark' : 'custom-background'
})
</script>

<template>
  <v-app id="app" :class="appBackgroundClass">
    <!-- Add this line: you don't need to import TheAppLayout
       because it is already registered as explained earlier  -->
    <TheAppLayout />
  </v-app>
</template>

<style scoped></style>
<style></style>
