import { useAuthStore } from '@/store/authentication/AuthStore'
import Cookies from 'js-cookie'
import { Router, RouteLocationNormalized } from 'vue-router'

export const startTokenRefresh = (router: Router) => {
  const authStore = useAuthStore()

  const excludedRoutes = ['/login', '/forget-password', '/sign-up', '/sign-up/invitation/'] // Routes where token refresh won't be started

  const refreshToken = async () => {
    try {
      if (Cookies.get('refresh_token')) {
        await authStore.refreshToken({ refresh: Cookies.get('refresh_token') })
        Cookies.set('access_token', authStore.userTokens.access)
      } else {
        router.push('/login')
      }
    } catch (error) {
      console.error('Error refreshing token:', error)
      router.push('/login')
    }
  }

  // Check if the current route is not in the excluded routes list
  const isTokenRefreshEnabled = (route: RouteLocationNormalized) => {
    return !excludedRoutes.some((routePath) => route.fullPath.startsWith(routePath))
  }

  // Schedule token refresh every 4 minutes if not in excluded routes
  const tokenRefreshInterval = setInterval(
    async () => {
      const currentRoute = router.currentRoute.value
      if (Cookies.get('refresh_token') && isTokenRefreshEnabled(currentRoute)) {
        try {
          await refreshToken()
        } catch (error) {
          clearInterval(tokenRefreshInterval) // Stop refreshing if an error occurs
        }
      }
    },
    4 * 60 * 1000
  )

  window.addEventListener('beforeunload', () => {
    clearInterval(tokenRefreshInterval)
  })
}
