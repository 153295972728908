<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useTheme } from 'vuetify'

const theme = useTheme()

const extraColor = computed(() => {
  return theme.global.current.value.dark ? '#e6e7e6' : '#27293e'
})

interface CounterProp {
  value: number
  checkbox?: boolean
  cost?: string
  title?: string
}

const props = defineProps<{
  counter: CounterProp
}>()

const counterValue = ref(props.counter.value)
const disableMinus = ref(counterValue.value === 1)
const checkbox = ref(props.counter.checkbox)
const costs = ref(props.counter.cost ? parseFloat(props.counter.cost) : 0) // Parse cost to number
const title = ref(props.counter.title)
const currentCost = ref<any>(0)

const incrementCounter = () => {
  counterValue.value++
  disableMinus.value = false
}

const decrementCounter = () => {
  if (counterValue.value > 1) {
    counterValue.value--
    disableMinus.value = counterValue.value === 1 // Disable minus button if counterValue becomes zero
  }
}

const totalAmount = computed(() => {
  return counterValue.value * costs.value
})

const emit = defineEmits()

const updateTotal = () => {
  checkbox.value = !checkbox.value // Toggle the checkbox value
  if (checkbox.value) {
    currentCost.value = totalAmount
  } else {
    currentCost.value = 0
  }
  emit('checkbox-clicked', currentCost.value)
}
watch(counterValue, () => {
  emit('counter-value-changed', counterValue.value)
})
</script>
<template>
  <div class="grid grid-cols-6 gap-x-2 justify-between items-center mt-2">
    <v-checkbox
      v-model="checkbox"
      :label="title"
      class="col-start-1 col-span-3"
      :style="{color:extraColor}"
      color="primary"
      density="compact"
      hide-details
      @click="updateTotal"
    ></v-checkbox>
    <div
      class="col-start-4 col-span-2 flex gap-x-1 justify-center items-center rounded-xl w-fit"
      style="background: rgba(251, 251, 251, 0.12)"
    >
      <div
        :class="disableMinus ? 'disable' : 'cursor-pointer'"
        class="border-2 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer"
        :style="{color:extraColor, borderColor:extraColor}"
        @click="decrementCounter"
      >
        <v-icon class="bg-transparent rounded-full w-100 h-100" :style="{color:extraColor}" size="small">
          mdi-minus
        </v-icon>
      </div>
      <v-text-field
        v-model="counterValue"
        bg-color="transparent"
        density="compact"
        disabled
        hide-details
        max-width="50px"
        min-width="40px"
        single-line
      >
      </v-text-field>
      <div
        class="border-2 border-theme-17 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer"
        :style="{color:extraColor}"
        @click="incrementCounter"
      >
        <v-icon class="bg-transparent rounded-full w-100 h-100" :style="{color:extraColor}" size="small">
          mdi-plus
        </v-icon>
      </div>
    </div>
    <span :style="{color:extraColor}" class="flex justify-center items-center text-base col-start-6 col-span-1"
      >${{ totalAmount }}</span
    >
  </div>
</template>

<style lang="scss" scoped>
.disable {
  cursor: not-allowed;
  border: 1px white solid;
}
</style>
