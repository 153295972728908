import BaseService from '../BaseService'

const CompanyServices = {
  async getCompanyInfo(id: number | string): Promise<any> {
    return await BaseService.get<any>(`/api/companies/${id}/`)
  },
  async updateCompanyInfo(id: number | string, data: FormData | Record<string, any>) {
    return await BaseService.put(`/api/companies/update/${id}/`, data)
  },
  async deleteMediaInCompanyProfile(id: any): Promise<any> {
    return await BaseService.delete<any>(`/api/companies/media/delete/${id}/`)
  },
  async getStaffCompany(): Promise<any> {
    return await BaseService.get<any>('/api/companies/staff/')
  },
  async inviteStaff(data:any): Promise<any> {
    return await BaseService.post<any>('/api/interactions/send-staff-invitation/', data)
  },
  async getInformation(data:any): Promise<any> {
    return await BaseService.get<any>(`/api/users/staff/register/?invitation_id=${data}`)
  },
  async getLanguageInformation(): Promise<any> {
    return await BaseService.get<any>("/api/languages/list/")
  },
  async registerUserStaff(data:any, id:any): Promise<any> {
    return await BaseService.post<any>(`/api/users/staff/register/?invitation_id=${id}`, data)
  },
  async deleteStaff(id:any): Promise<any> {
    return await BaseService.delete<any>(`/api/companies/staff/delete/${id}/`)
  },
}
export default CompanyServices
