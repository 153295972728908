import { App } from 'vue'

export function registerLayouts(app: App<Element>) {
  const layouts = import.meta.glob('./*.vue', { eager: true }) as Record<string, any>

  for (const path in layouts) {
    if (Object.prototype.hasOwnProperty.call(layouts, path)) {
      const layoutModule = layouts[path]
      const layout = layoutModule.default

      if (layout && layout.name) {
        app.component(layout.name, layout)
      }
    }
  }
}
