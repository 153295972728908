import BaseService from '../BaseService'

const API_ENDPOINTS = {
  getAllNotifications: '/api/notifications/notifications/',
  getSystemNotifications: (page: any) =>
    `/api/notifications/notifications/?is_system=true&page=${page}`,
  getUnreadCounter: '/api/notifications/notifications/?is_read=false&is_system=true',
  getNotificationById: (id: number | string) => `/api/notifications/notifications/${id}/`,
  markAsRead: (id: number | string) => `api/notifications/notifications/${id}/`,
  getAllConnectionRequests: (page: number) =>
    `/api/networking/network/?page=${page}&ordering=-created_at`,
  getAllInvitations: (page: number) =>
    `/api/interactions/invitations/?page=${page}&ordering=-created_at`,
  getPendingInvitations: '/api/interactions/invitations/?status=PENDING',
  getNonPendingConnectionRequests: '/api/networking/network/?search!=Pending',
  getPendingConnectionRequests: '/api/networking/network/?search=Pending',
  getAcceptedConnectionRequests: '/api/networking/network/?search=Accepted',
  getDeniedConnectionRequests: '/api/networking/network/?search=Declined',
  updateRequestStatus: (id: number | string) => `/api/networking/network/${id}/`,
  networkingNotificationsCounter: '/api/networking/received/'
}

const realEstateServices = {
  async getAllNotifications(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getAllNotifications)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getSystemNotifications(page = 1): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getSystemNotifications(page))
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getUnreadCounter(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getUnreadCounter)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getPendingConnectionRequests(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getPendingConnectionRequests)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getNonPendingConnectionRequests(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getNonPendingConnectionRequests)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getAcceptedConnectionRequests(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getAcceptedConnectionRequests)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getDeniedConnectionRequests(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getDeniedConnectionRequests)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getNotificationById(id: number | string): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getNotificationById(id))
    } catch (error) {
      console.error(`Error fetching real notification id ${id}:`, error)
      throw error
    }
  },
  async markAsRead(id: number | string): Promise<any> {
    try {
      return await BaseService.patch<any>(API_ENDPOINTS.markAsRead(id), { is_read: true })
    } catch (error) {
      console.error(`Error marking notification id ${id} as read:`, error)
      throw error
    }
  },
  async getAllConnectionRequests(page = 1): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getAllConnectionRequests(page))
    } catch (error) {
      console.error('Error fetching connection request info:', error)
      throw error
    }
  },
  async getAllInvitations(page = 1): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getAllInvitations(page))
    } catch (error) {
      console.error('Error fetching connection request info:', error)
      throw error
    }
  },
  async getPendingInvitations(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getPendingInvitations)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async acceptRequest(id: number | string): Promise<any> {
    const formData = new FormData()
    formData.append('status', 'accepted')

    try {
      return await BaseService.patch<any>(
        API_ENDPOINTS.updateRequestStatus(id),
        formData,
        'multipart/form-data'
      )
    } catch (error) {
      console.error(`Error marking notification id ${id} as Accept:`, error)
      throw error
    }
  },
  async rejectRequest(id: number | string): Promise<any> {
    const formData = new FormData()
    formData.append('status', 'declined')

    try {
      return await BaseService.patch<any>(
        API_ENDPOINTS.updateRequestStatus(id),
        formData,
        'multipart/form-data'
      )
    } catch (error) {
      console.error(`Error marking notification id ${id} as Reject:`, error)
      throw error
    }
  },
  async revertRequestPending(id: number | string): Promise<any> {
    const formData = new FormData()
    formData.append('status', 'pending')

    try {
      return await BaseService.patch<any>(
        API_ENDPOINTS.updateRequestStatus(id),
        formData,
        'multipart/form-data'
      )
    } catch (error) {
      console.error(`Error marking notification id ${id} as Pending:`, error)
      throw error
    }
  },
  async deleteRequest(id: number | string): Promise<any> {
    try {
      return await BaseService.delete<any>(API_ENDPOINTS.updateRequestStatus(id))
    } catch (error) {
      console.error(`Error deleting request id ${id}:`, error)
      throw error
    }
  },
  async getNetworkingNotifications(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.networkingNotificationsCounter)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
}
export default realEstateServices
